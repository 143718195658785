.search {
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}
.bi-search {
  z-index: 1;
}

.search input {
  height: 50px;
  text-indent: 25px;
  border: 2px solid #d6d4d4;
  position: sticky;
}

.search input:focus {
  box-shadow: none;
  border: 2px solid#0d6efd;
}

.icon {
  position: absolute;
  top: 13px;
  left: 16px;
}

.search button {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 40px;
  width: 75px;
}
